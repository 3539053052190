import React, { useEffect, useState, useCallback } from "react";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useLocation } from "react-router-dom";

const InputText = ({ label, field, id, placeholder, span = 2, index = 1 }) => {
  return (
    <div className={"lg:col-span-" + span + " my-4 w-100  lg:mx-10"}>
      <label
        htmlFor={field}
        className="block text-lg  lg:text-xl font-bold leading-6 text-primary"
      >
        {label}
      </label>
      <div className="mt-2">
        <div className="flex ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
          <input
            type="text"
            name={id + "-" + field}
            id={id}
            index={index}
            autoComplete={field}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder={placeholder}
          />
        </div>
      </div>
    </div>
  );
};

const DateField = ({
  label,
  field,
  id,
  required,
  placeholder,
  span = 2,
  index = 1,
}) => {
  return (
    <div className={"lg:col-span-" + span + " my-4 w-100  lg:mx-10"}>
      <label
        htmlFor={field}
        className="block text-lg  lg:text-xl font-bold leading-6 text-primary"
      >
        {label}
      </label>
      <div className="mt-2">
        <div className="flex ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
          <input
            type="date"
            name={id + "-" + field}
            id={id}
            index={index}
            autoComplete={field}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder={placeholder}
            required={required}
          />
        </div>
      </div>
    </div>
  );
};

const ChoiceField = ({
  label,
  field,
  id,
  placeholder,
  options,
  span = 2,
  index = 1,
}) => {
  return (
    <div className={"lg:col-span-" + span + " my-4 w-100  lg:mx-10"}>
      <label
        htmlFor={field}
        className="block text-lg  lg:text-xl font-bold leading-6 text-primary"
      >
        {label}
      </label>
      <div className="mt-2">
        <div className="flex ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
          <select
            name={id + "-" + field}
            id={id}
            index={index}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            defaultValue=""
          >
            <option value="" disabled>
              Selecciona una opción
            </option>
            {options.split(",").map((option_value, choiceIndex) => (
              <option key={choiceIndex} value={option_value}>
                {option_value}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};
const serverUrl = "https://nurseapi.riquelme.dev";
//const serverUrl = "http://127.0.0.1:8000";
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const range = (n) => [...Array(n).keys()];

const Formulario = () => {
  const query = useQuery();
  const formId = query.get("formId");
  const fetchUrl = `${serverUrl}/api/questions/${formId}/`;
  const saveUrl = `${serverUrl}/api/answer/`;
  // Estados para almacenar los valores de los inputs
  //"/formId/?format=json",
  const [questions, setQuestions] = useState({ question_groups: [] });
  const [name, setName] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [showSubmit, setShowSubmit] = useState(false);
  const [showNextStep, setShowNextStep] = useState(false);
  const [loadedData, setloadedData] = useState(false);
  const [showPrevStep, setShowPrevStep] = useState(false);
  const [questionGroupIndex, setQuestionGroupIndex] = useState({});
  const [finished, setfinished] = useState(false);

  const nextStep = (e) => {
    e.preventDefault();
    setCurrentStep((prevStep) => prevStep + 1);
  };
  const getData = useCallback(() => {
    fetch(fetchUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        if (!response.ok) {
          setName("--");
          setloadedData(true);

          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json();
      })
      .then(function (myJson) {
        console.log(myJson);
        setQuestions(myJson.question_form);
        setName(myJson.user.name);
        setloadedData(true);
      })
      .catch(function (error) {
        setName("--");
        setloadedData(true);
        setQuestions(false);
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  }, [fetchUrl]);

  useEffect(() => {
    if (currentStep === 0) {
      setShowPrevStep(false);
    } else {
      setShowPrevStep(true);
    }
    if (currentStep === questions?.question_groups?.length - 1) {
      setShowSubmit(true);
      setShowNextStep(false);
    } else {
      setShowSubmit(false);
      setShowNextStep(true);
    }
  }, [currentStep, questions?.question_groups?.length]);

  useEffect(() => {
    if (!loadedData) {
      getData();
    }
  }, [loadedData, getData]);

  const prevStep = (e) => {
    e.preventDefault();
    setShowSubmit(false);
    setShowNextStep(true);
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const addQuestionGroup = (e, index) => {
    e.preventDefault();
    setQuestionGroupIndex((prevIndex) => ({
      ...prevIndex,
      [index]: (prevIndex[index] || 1) + 1,
    }));
  };
  const removeQuestionGroup = (e, index) => {
    e.preventDefault();
    setQuestionGroupIndex((prevIndex) => ({
      ...prevIndex,
      [index]: prevIndex[index] - 1,
    }));
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault(); // Previene el comportamiento por defecto del formulario

    const json_data = getJsonfromInputs();
    postData(saveUrl, json_data);
  };

  const postData = (url = "", data = {}) => {
    // send post to the url with the data
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setQuestions({ question_groups: [] });
        setfinished(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert(
          "Hubo un error al enviar tus respuestas, por favor intenta de nuevo"
        );
      });
  };

  // Validación de los campos
  // por cada campo en questions, validar que el campo tenga un valor y
  // guardarlo en un json con el valor del campo
  // si todos los campos tienen valor, enviar el json
  const getJsonfromInputs = () => {
    let json_data = {};
    let required_fields = [];
    json_data.userform_id = formId;
    const answers = [];
    questions.question_groups.map((question_group) => {
      question_group.questions.map((question) => {
        const elements = document.querySelectorAll(`[id^="${question.id}"]`);
        Array.from(elements).map((element) => {
          let value = element.value;
          let index_value = element.getAttribute("index");
          if (question.required && value === "") {
            // add question.question to required_fields
            required_fields.push([question.question]);
            return null;
          }
          answers.push({
            question_id: question.id,
            answer: value,
            index: index_value || 1,
          });
          return null;
        });
        return null;
      });
      return null;
    });
    if (required_fields.length > 0) {
      alert(
        "Los siguientes campos son requeridos: " + required_fields.join(", ")
      );
      return null;
    }
    json_data.answers = answers;
    return json_data;
  };

  const renderQuestion = (question, questionIndex, index) => {
    switch (question.question_type) {
      case "text":
      case "number":
        return (
          <InputText
            key={questionIndex}
            index={index}
            label={question.question}
            field={question.question}
            id={question.id}
            placeholder={question.placeholder}
            span={question.col || 2}
          />
        );
      case "choice":
        return (
          <ChoiceField
            key={questionIndex}
            index={index}
            label={question.question}
            field={question.question}
            id={question.id}
            placeholder={question.placeholder}
            options={question.choices} // Convertir choices a un array
            span={question.col || 2}
          />
        );
      case "date":
        return (
          <DateField
            key={questionIndex}
            index={index}
            label={question.question}
            field={question.question}
            id={question.id}
            required={question.required}
            placeholder={question.placeholder}
            span={question.col || 2}
          />
        );
      default:
        return (
          <p key={questionIndex}>
            Unsupported question type: {question.question_type}
          </p>
        );
    }
  };

  return (
    <div className="flex flex-col lg:flex-row p-8 bg-white">
      <div className="basis-full lg:basis-1/3 mb-10">
        <h2 className="text-3xl  text-primary font-bold mb-4">Regístrate</h2>
        <p className="pr-10">
          Hola {name}, completa la información para que no te pierdas ninguna
          oferta laboral
        </p>
      </div>
      <div className="ml-2 lg:ml-10 basis-full lg:basis-2/3">
        <form>
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12 grid">
              {questions.question_groups &&
              questions.question_groups.length > 0 ? (
                questions.question_groups.map((question_group, stepIndex) => (
                  <div
                    key={stepIndex}
                    className={`block w-full w-100 step ${
                      stepIndex === currentStep ? "active" : "hidden"
                    }`}
                  >
                    <h2 className="text-3xl  text-primary font-bold mb-4">
                      {question_group.name}
                    </h2>
                    <p className="text-xl  text-primary font-bold mb-4">
                      {question_group.description}
                    </p>

                    {range(questionGroupIndex[stepIndex] || 1).map(
                      (repeatIndex) => (
                        <div key={repeatIndex}>
                          {question_group.questions.map(
                            (question, questionIndex) =>
                              renderQuestion(
                                question,
                                questionIndex,
                                repeatIndex + 1
                              )
                          )}
                          {repeatIndex > 0 &&
                            repeatIndex ===
                              (questionGroupIndex[stepIndex] || 1) - 1 && (
                              <button
                                onClick={(e) =>
                                  removeQuestionGroup(e, stepIndex)
                                }
                                className="bg-gray-200 mt-10 px-10 block flex-1 text-xl py-10 w-100 lg:mx-10 py-2 text-lg font-bold text-primary hover:text-white hover:bg-gray-600 hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                              >
                                <TrashIcon className="inline h-5 w-5 ml-2" />
                              </button>
                            )}
                          {question_group.multiples && <hr className="my-10" />}
                        </div>
                      )
                    )}
                    {question_group.multiples && (
                      <button
                        onClick={(e) => addQuestionGroup(e, stepIndex)}
                        className="bg-gray-200 mt-10 px-10 block flex-1 text-xl py-10 w-100 lg:mx-10 py-2 text-lg font-bold text-primary hover:text-white hover:bg-gray-600 hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                      >
                        Agregar otro{" "}
                        <PlusIcon className="inline h-5 w-5 ml-2" />
                      </button>
                    )}
                  </div>
                ))
              ) : (
                <div>
                  <p className="text-2xl font-bold leading-7 text-gray-900">
                    {finished
                      ? " Muchas gracias por tu tiempo, estás cada vez más cerca de encontrar tu Match Perfecto!"
                      : questions === false
                      ? "No se encontraron preguntas"
                      : "Cargando Preguntas ..."}
                  </p>
                </div>
              )}
            </div>
          </div>
          {showPrevStep && !finished ? (
            <div className="mt-6 flex items-center gap-x-6">
              <button
                onClick={prevStep}
                className="bg-gray-400 block flex-1 text-xl py-10 w-100 lg:mx-10 py-2 text-lg font-bold text-white hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
              >
                Back
              </button>
            </div>
          ) : null}
          {showNextStep && !finished ? (
            <div className="mt-6 flex items-center gap-x-6">
              <button
                onClick={nextStep}
                className="bg-primary2 block flex-1 text-xl py-10 w-100 lg:mx-10 py-2 text-lg font-bold text-white hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
              >
                Continuar
              </button>
            </div>
          ) : null}
          {showSubmit ? (
            <div className="mt-6 flex items-center gap-x-6">
              <button
                type="submit"
                onClick={handleSubmit}
                className="bg-primary block flex-1 text-xl py-10 w-100 lg:mx-10 py-2 text-lg font-bold text-white hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
              >
                Enviar
              </button>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default Formulario;
